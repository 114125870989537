
example.PropertyPane = Class.extend({

	init:function(elementId, view, parentScope)
	{
		this.html = $("#"+elementId);
		this.view = view;
		this.parentScope = parentScope;
		// Databinding: helper attributes for the databinding
		this.selectedFigure = null;
		this.updateCallback = null;
        view.on("select", $.proxy(this.onSelectionChanged,this));
    },

	/**
	 * @method
	 * Called if the selection in the canvas has been changed. You must register this
	 * on the canvas to receive this event.
	 *
     * @param {draw2d.Canvas} emitter
     * @param {Object} event
     * @param {draw2d.Figure} event.figure
	 */
	onSelectionChanged : function(emitter, event)
	{
		// Databinding: deregister the old update listener
		if(this.selectedFigure!==null && this.updateCallback !==null){
			this.selectedFigure.off(this.updateCallback);
		}
		
		this.selectedFigure = event.figure;
		
        this.html.html("");
        if(event.figure instanceof draw2d.shape.node.Node){
        	this.showPropertiesOpAmp(event.figure);
        }
	},


	/**
	 * @method
	 * Called if the selection in the canvas has been changed. You must register this
	 * on the canvas to receive this event.
	 * 
	 * @param {draw2d.Figure} figure
	 */
	showPropertiesOpAmp : function(figure)
	{
		// Set some good defaults
		let _self = this;
        // (better you create  new class and set the defaults in the init method)
        var userData = figure.getUserData();
        if(userData===null){
          figure.setUserData(userData={name:""});   
		}

        
        // simple x/y coordinate display
		//
		// Informazioni di default
        this.html.append(
			`<div id="property_position_container" class="panel panel-default">
				<div class="panel-heading"> Proprietà </div>
				<div class="panel-body" id="position_panel">
					<div class="input-group" ></div>
					<div class="form-group">
						Tipo: <input id="Name" type="text" class="form-control-style" value="${figure.getUserData().name}" disabled>
						Descrizione: <textarea id="taskDescription" class="form-control-style" disabled>${figure.getUserData().description}</textarea>
					</div>
				</div>
				<div class="panel-heading"> Configurazione </div>
				<div class="panel-body" id="position_panel">
					<div class="input-group" ></div>
					<div class="form-group">
						Tipo di task: 
						<select id="taskSelect">
							<option target=1 >Singola</option>
							<option target=2 >Multipla</option>
						</select> </br>
						Descrizione: <textarea id="taskDescription" class="form-control-style">${figure.getUserData().task_description}</textarea>
						Partecipanti: <button id="bottontest" onclick="setPartecipants()" class="btn btn-primary">Partecipanti</button></br>
						Tempo svolgimento(GG:HH): <input type="text" class="form-control-style" value="${figure.getUserData().max_time}">
						
					</div>
				</div>
			</div>`
			);
			if(userData.type == 'Singola'){
				document.getElementById("taskSelect").selectedIndex = "0";
			} else {
				document.getElementById("taskSelect").selectedIndex = "1";
			}
    	// Databinding: Figure --> UI
        //
        var isInUpdate=false;
    	figure.on("move",function(){
    		if(isInUpdate) return;
    		isInUpdate = true; // avoid recursion
    		$("#property_position_x").val(figure.getPosition().x);
       		$("#property_position_y").val(figure.getPosition().y);
       		isInUpdate = false;
		   });
		   
		setPartecipants = function(){
			console.log("Stampa test");
			_self.parentScope.setPartecipants();

		}

		// Databinding: UI --> Figure
		// Functins
		checkPort = function(figure){
			let MyOutputPortLocator = draw2d.layout.locator.PortLocator.extend({
				init:function( ){
				  this._super();
				},
				relocate:function(index, figure){
					var p = figure.getParent();
					this.applyConsiderRotation(figure, p.getWidth(), 0);
				}
			});
			let userD = figure.getUserData();
			let ports = figure.getOutputPorts();
			ports = ports.data;
			console.log("Ports: 0", ports);
			if(ports && ports.length == 1 && userD.type == 'Multipla'){
				console.log("entra");				
				figure.createPort("output");
				figure.repaint();
				let ps = figure.getOutputPorts();
				console.log("Porte: ", ps.data);
				ps.data[1].setUserData({type: 'single'});
				ps.data[1].locatorAttr = {
					x:100,
					y:100
				}
				console.log("Ottimo: ", ps.data[1].getLocator());
			}
			if(ports && ports.length == 2 && userD.type == 'Singola'){
				console.log("entra");
				let ps = figure.getOutputPorts();
				let toDelPort;
				ps.data.forEach((p) => {
					console.log("port: ", p);
					let userD = p.getUserData();
					if(userD && userD.type == 'single'){
						console.log("Posizione trovata");
						toDelPort = p;
					}
				})
				console.log("Porta da cancellare: ", toDelPort);
				
				figure.removePort(toDelPort);
				// let outputLocator = new draw2d.layout.locator.OutputPortLocator();
				// figure.createPort("output", new MyOutputPortLocator())
				// figure.add(new draw2d.Port(), outputLocator);
			}
			if(userD.type=='Singola'){
				console.log("Porte (singola): ", figure.getOutputPorts());
			} else {
				console.log("Porte (multipla): ", figure.getOutputPorts());
			}
		}
    	$("#position_panel input").on("change", function(){
    	    // with undo/redo support
    	    var cmd = new draw2d.command.CommandMove(figure);
    	    cmd.setPosition(parseInt($("#property_position_x").val()),parseInt($("#property_position_y").val()));
    	    figure.getCanvas().getCommandStack().execute(cmd);
    	});
    	$("#property_name").on("change", function(){
    		figure.getUserData().task_name=$("#property_name").val();
		});
		$("#taskSelect").on("change", async function(){
			figure.getUserData().type=$("#taskSelect").val();
			await checkPort(figure);
    	});

	}
});


// '<div id="property_position_container" class="panel panel-default">'+
// ' <div class="panel-heading " >'+
// '     Position'+
// '</div>'+
// ' <div class="panel-body" id="position_panel">'+
// '   <div class="form-group">'+
// '       <div class="input-group" ></div> '+
// '       x <input id="property_position_x" type="text" class="form-control-style"/><br>'+
// '       y <input id="property_position_y" type="text" class="form-control-style"/>'+
// '   </div>'+
// ' </div>'+
// '</div>'+

// '<div id="property_position_container" class="panel panel-default">'+
// ' <div class="panel-heading " >'+
// '     Custom Property'+
// '</div>'+
// ' <div class="panel-body" id="userdata_panel">'+
// '   <div class="form-group">'+
// '       <div class="input-group" ></div> '+ 
// '       Nome: <input id="property_name" type="text" class="form-control-style" value="'+figure.getUserData().task_name+'"/>'+
// '   </div>'+
// '   <div class="form-group">'+
// '       <div class="input-group" ></div> '+ 
// '       Tipo: <select id="property_type" class="form-control-style" value="'+figure.getUserData().type+'">'+
// '			<option>Singola</option>'+
// '			<option>Multipla</option>'+
// '		<select/>'+
// '   </div>'+
// '<button id="bottontest" onclick="test()" class="btn btn-primary">Button test</button>'+
// ' </div>'+
// '</div>'