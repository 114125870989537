example.View = draw2d.Canvas.extend({
	
	init:function(id){
		this._super(id);
		
		this.setScrollArea("#"+id);
		
		this.currentDropConnection = null;
	},

    /**
     * @method
     * Called if the DragDrop object is moving around.<br>
     * <br>
     * Graphiti use the jQuery draggable/droppable lib. Please inspect
     * http://jqueryui.com/demos/droppable/ for further information.
     * 
     * @param {HTMLElement} droppedDomNode The dragged DOM element.
     * @param {Number} x the x coordinate of the drag
     * @param {Number} y the y coordinate of the drag
     * 
     * @template
     **/
    onDrag:function(droppedDomNode, x, y )
    {
        console.log("Drag on: ", droppedDomNode);
        
    },
    
    /**
     * @method
     * Called if the user drop the droppedDomNode onto the canvas.<br>
     * <br>
     * Draw2D use the jQuery draggable/droppable lib. Please inspect
     * http://jqueryui.com/demos/droppable/ for further information.
     * 
     * @param {HTMLElement} droppedDomNode The dropped DOM element.
     * @param {Number} x the x coordinate of the drop
     * @param {Number} y the y coordinate of the drop
     * @param {Boolean} shiftKey true if the shift key has been pressed during this event
     * @param {Boolean} ctrlKey true if the ctrl key has been pressed during the event
     * @private
     **/
    onDrop : function(droppedDomNode, x, y, shiftKey, ctrlKey)
    {
        console.log("Drop on: ", droppedDomNode);

        // Shape Type
        let type = $(droppedDomNode).data("shape");
        // Proprietà Shape
        let proprieties = $(droppedDomNode).data("proprieties");
        // Creo la figura
        let figure = eval("new "+type+"();");
        console.log("type: ", type);
        console.log("proprieties: ", JSON.stringify(proprieties));
        // Aggiungo le proprietà personalizzate
        figure.attr(proprieties)
        // Creo un etichetta col nome della proprietà
        figure.add(new draw2d.shape.basic.Label({text: `${proprieties.userData.task_name}`, stroke: 0}) , new draw2d.layout.locator.CenterLocator(figure));
        
        
        // create a command for the undo/redo support
        let command = new draw2d.command.CommandAdd(this, figure, x, y);
        this.getCommandStack().execute(command);
    }
});

